<template>
 <v-layout>
 <v-footer  style="background:  #333399;"  class=" text-center d-flex flex-column"
  >
  <v-row class="w-100  pa-12">
      <v-col  cols="12" sm="3" >
       <ul style="list-style-type: none;color: white;text-align: start;line-height:3">
        <li>Help Center</li>
        <router-link to="/privacyPolicy" class="text-white"><li>Privacy and Cookies Statement</li></router-link>
        <router-link to="/about" class="text-white"> <li>About Tripper</li></router-link>
    
       </ul>
      </v-col>
      <v-col  cols="12" sm="3" >
        <ul style="list-style-type: none;color: white;text-align: start;line-height:3">
          <router-link to="/sitemap" class="text-white"><li>Sitemap</li></router-link>
         <li style="    margin-left: -1rem;" > <v-list-item href="https://vendor.trippertravels.com/" target="_blank" style="text-decoration: underline;">Supplier Sign Up</v-list-item></li>


       </ul>
      </v-col>
      <v-col  cols="12" sm="3" >
        <ul style="list-style-type: none;color: white;text-align: start;line-height:3">
        <li>Travel Agents</li>
        <li>Become an Affiliate</li>
        <li>News</li>
         
       </ul>
      </v-col>
      <v-col  cols="12" sm="3" >
        <ul style="list-style-type: none;color: white;text-align: start;line-height:3">
        <li>Tripper blog</li>
        <router-link to="/termsAndConditions" class="text-white"> <li>Terms & Conditions</li>  </router-link>     
       </ul>
      </v-col>
      <!-- <v-divider :thickness="3" class="pa-3"></v-divider> -->
         </v-row>

       
  </v-footer>
</v-layout>
</template>
<script>
export default{
    name: 'footer-list', 

    methods: {
      supplier() {
			this.$router.push('https://vendor.trippertravels.com/').then(() => { this.$router.go(0) })
      },
    }
}
</script>