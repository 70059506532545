import axios from "axios";


export default axios.create({
  // baseURL: "https://www.api.makassociates.in/",
  baseURL: "https://api.trippertravels.com/",
  headers: {
    "Content-type": "application/json",
    "Accept":"application/vnd.api.v1+json"
  }
});
