<template>
  <!-- <v-card> -->
  <v-layout>
  

    <v-app-bar>
      <v-app-bar-nav-icon class="mobicone" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link to="/" style="text-decoration: none">  <v-app-bar-title class="mobicone" style="color: #008768;"> <v-img :width="200" cover src="../../../public/images/Artboard 1.jpg"></v-img></v-app-bar-title></router-link>

      <v-row class="desktopview">
        <v-col cols="12" sm="2" class="mt-4">

          <router-link to="/" style="text-decoration: none">
            <!-- <h1 style="color: #008768;">Tripper</h1> -->
            <v-img :width="90" class="ml-7" cover src="../../../public/images/logo.jpg"></v-img>
          </router-link>

        </v-col>
        <v-col cols="12" sm="6" style="margin-top: 2rem;">

          <v-menu transition="slide-x-transition" v-for="menus in menulist" :key="menus.id">
            <template v-slot:activator="{ props }">
              <v-btn append-icon="mdi-chevron-down" v-bind="props" class="text-capitalize" @click="getid(menus.id)">
                {{ menus.name }}
              </v-btn>
            </template>

            <v-list v-model:opened="open">
            
                <v-list-group v-for="submenu of submenulist" :key="submenu.id">
                  <template v-slot:activator="{ props }">
                    <v-list-item  v-bind="props"
                      @click="getsubid(submenu.id)">{{ submenu.name }}
                    </v-list-item>
                  </template>

                  <v-list-item v-for="thirdmenu in thirdmenulist" :key="thirdmenu.id"  @click="getthirdid(thirdmenu.id)">
                    {{ thirdmenu.name }}
                  </v-list-item>
                </v-list-group>

            
            </v-list>
          </v-menu>


        </v-col>
        <v-col cols="12" sm="4" style="margin-top:2rem ;" >

          <v-menu transition="slide-x-transition" v-if="isLoggedIn"  >
        <template v-slot:activator="{ props }">
          <v-btn prepend-icon="mdi-account" style=" float: right;" append-icon="mdi-chevron-down" v-bind="props" class="text-capitalize">
            {{ usernames }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="text-center">
            <!-- <router-link to="/profile_list" style="text-decoration: none;"><v-list-item-title> <v-btn variant="plain"
                  prepend-icon="mdi-face-profile">Profile</v-btn></v-list-item-title></router-link> -->
            <v-btn variant="plain" prepend-icon="mdi-logout" v-if="isLoggedIn" @click="logout">
              Logout

            </v-btn>

          </v-list-item>
        </v-list>
      </v-menu>
      <span v-else >

        <router-link to="/login" >  <v-btn variant="outlined"   rounded="pill">
            Login
          </v-btn></router-link>
          <router-link to="/Register">  <v-btn variant="outlined" style="background: #333399;color: #ffffff;"  rounded="pill" class="ml-5 ">
            Sign Up
          </v-btn></router-link>

      </span>
        </v-col>
        <!-- <v-col cols="12" sm="4" class="mt-2" else>
          <router-link to="/login">  <v-btn variant="outlined" rounded="pill">
            Login
          </v-btn></router-link>
          <router-link to="/Register">  <v-btn variant="outlined" rounded="pill" class="ml-5">
            Sign Up
          </v-btn></router-link>



        </v-col> -->



      </v-row>
    </v-app-bar>
    <v-navigation-drawer  v-model="drawer">
      <v-list>
        <v-menu transition="slide-x-transition" v-for="menus in menulist" :key="menus.id">
            <template v-slot:activator="{ props }">
              <v-btn append-icon="mdi-chevron-down" variant="plain" v-bind="props" class="text-capitalize" @click="getid(menus.id)">
                {{ menus.name }}
              </v-btn>
            </template>

            <v-list v-model:opened="open">
            
                <v-list-group v-for="submenu of submenulist" :key="submenu.id">
                  <template v-slot:activator="{ props }">
                    <v-list-item  v-bind="props"
                      @click="getsubid(submenu.id)">{{ submenu.name }}
                    </v-list-item>
                  </template>

                  <v-list-item v-for="thirdmenu in thirdmenulist" :key="thirdmenu.id"  @click="getthirdid(thirdmenu.id)">
                    {{ thirdmenu.name }}
                  </v-list-item>
                </v-list-group>

            
            </v-list>
          </v-menu>

      </v-list>

      
      <template v-slot:append>
          <div class="pa-2">
            <v-menu transition="slide-x-transition" v-if="isLoggedIn" >
        <template v-slot:activator="{ props }">
          <v-btn prepend-icon="mdi-account" style=" float: right;" append-icon="mdi-chevron-down" v-bind="props" class="text-capitalize">
            {{ usernames }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="text-center">
            <!-- <router-link to="/profile_list" style="text-decoration: none;"><v-list-item-title> <v-btn variant="plain"
                  prepend-icon="mdi-face-profile">Profile</v-btn></v-list-item-title></router-link> -->
            <v-btn variant="plain" prepend-icon="mdi-logout" v-if="isLoggedIn" @click="logout">
              Logout

            </v-btn>

          </v-list-item>
        </v-list>
      </v-menu>
      <span v-else>

        <router-link to="/login" >  <v-btn variant="outlined" block  class="mt-2" >
            Login
          </v-btn></router-link>
          <router-link to="/Register">  <v-btn variant="outlined" block class=" mt-2">
            Sign Up
          </v-btn></router-link>

      </span>
          </div>
        </template>
    </v-navigation-drawer>



    
  </v-layout>
  <!-- </v-card> -->
  <v-card>
   
  </v-card>

</template>
<script>
import axios from "axios";
import http from "../../http";
// import image from '@/assets/ST_Logo.svg';
export default {
  data() {
    return {
      drawer: false,
      menulist: [],
      submenulist: [],
      thirdmenulist: [],
      open: ['Users'],
      usernames: '',
     
    }
  },
  computed: {
    isLoggedIn: function () {
      return localStorage.getItem('token');
    },
    // username: function () {
    //   return localStorage.getItem('name');
    // }
  },
  methods: {
    getthirdid(id){
      this.$router.push('/viewproducts/'+id ).then(() => { this.$router.go(0) });
      
      console.log('thirdid',id)
    },


    logout: function () {
      console.log(localStorage.removeItem('token'));
      localStorage.removeItem('token');
      sessionStorage.clear();
      this.$router.push('/').then(() => { this.$router.go(0) });
    },
    getid(id) {
      this.submenulist =[];
      console.log('submenu', id)
      axios.get(http.defaults.baseURL + 'api/ShowSubMenu/' + id + '?include=SubMenus')
        .then(response => {
          this.submenulist = response.data.data.SubMenus.data;
          console.log('submenu', response.data.data.SubMenus)
        })
    },
    getsubid(subid) {
      this.thirdmenulist =[];
      axios.get(http.defaults.baseURL + 'api/ShowThirdMenu/' + subid + '?include=ThirdMenus')
        .then(response => {
          this.thirdmenulist = response.data.data.ThirdMenus.data;
          console.log('third', response.data.data.ThirdMenus)
        })

    },

    
  },
  mounted: function () {


    axios.get(http.defaults.baseURL + 'api/ListMenu')
      .then(response => {
        console.log('menu', response.data.data)
        this.menulist = response.data.data;



      });
      if(localStorage.getItem('token')){
        axios.get(http.defaults.baseURL + 'api/me')
      .then(response => {
      
          this.usernames = response.data.data.name;

      
      })
      }



  },
}
</script>
<style>
.v-btn--variant-elevated {
  color: #000000 !important;
}
.v-toolbar__content {

    height: 6rem !important;
}
.v-app-bar.v-toolbar:not(.v-toolbar--flat) {
  box-shadow: 0px 2px 0px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px -5px 0px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 0px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
}

.text-subtitle-1 {
  font-size: .8rem !important;
  /* font-family: montserrat !important; */

}

/* html {
  font-family: 'Montserrat', sans-serif !important;

} */

.v-data-table-footer {
  display: none !important;
}

.text-button {
  font-size: 0.75rem !important;
  font-weight: 800 !important;

}
.mobicone{
    display: none;
}

@media only screen and (max-width: 600px) {
  .desktopview {
    display: none;
    background-color: #f00;
  }
  .mobicone{
    display: flex;
}
}
</style>
