import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    name: 'productdetails',
    path: '/productdetails/:id',
component: () => import('../views/proddetails/proddetails.vue'),

},
{
  path: '/Register',
  name: '/register_create',
meta:{requiresAuth:true},

component: () => import('../views/Login/sign_up.vue'),

},
{
  path: '/login',
  name: '/login',
meta:{requiresAuth:true},

component: () => import('../views/Login/Sign-In.vue'),

},
{
  name: 'viewproducts',
  path: '/viewproducts/:id',
component: () => import('../views/proddetails/viewproduct.vue'),

},
{
  path: '/privacyPolicy',
  name: '/privacyPolicy',
meta:{requiresAuth:true},

component: () => import('../views/Footersection/PrivacyCookingstatement.vue'),

},
{
  path: '/about',
  name: '/about',
meta:{requiresAuth:true},

component: () => import('../views/Footersection/about.vue'),

},
{
  path: '/sitemap',
  name: '/sitemap',
meta:{requiresAuth:true},

component: () => import('../views/Footersection/Sitemap.vue'),

},
{
  path: '/termsAndConditions',
  name: '/termsAndConditions',
meta:{requiresAuth:true},

component: () => import('../views/Footersection/Termsandcondtion.vue'),

},
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
