<template>
  <div class="home">
    <TopHeader />

    <v-carousel :show-arrows="false" hide-delimiters>
      <v-carousel-item src="../../public/images/cover1.jpg" cover>
        <v-row no-gutters>
          <v-col cols="12" sm="12" class="ml-14" style="margin-top: 10rem;">

            <h1 class="text-white text-center font-weight-black aligncen">Do more with Tripper
            </h1>
            <p class="text-white text-center font-weight-bold aligncen1">One site,300,000+ travel experiences you'll
              remember.</p>
            <v-card style="margin-top: 9.5rem;" class="mx-auto datetop" rounded="pill">
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6">

                  <v-text-field v-model="model" class="mt-3 mb-6 ml-3 width" hide-details="auto" variant="solo" theme="light"
                    label="Where to ?"  clearable></v-text-field>

                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <VueDatePicker class="mt-3 ml-3 width" v-model="date" model-type="dd/MM/yyyy" rounded
                    :enable-time-picker="false" placeholder="Select Date" :teleport="true" />

                </v-col>
                <v-col cols="12" sm="1" md="1" class=" mt-6">
                  <v-icon icon="mdi-magnify" size="large" class="ml-2" style="font-size: 2.3em;"></v-icon>
                  <!-- <v-btn icon="mdi-magnify " class="cen" size="large"></v-btn> -->
                </v-col>
              </v-row>


            </v-card>
          </v-col>

        </v-row>

      </v-carousel-item>
    </v-carousel>

    <!-- <h1 class="mt-4 text-h6 text-md-h5 text-lg-h4 font-weight-black">Discover</h1>
<p class="font-weight-medium">Get inspiration for your next trip</p>  <v-card>
    <v-tabs
      v-model="tab1"
      align-tabs="center"
      color="deep-purple-accent-4"
    >
      <v-tab :value="1">Landscape</v-tab>
      <v-tab :value="2">City</v-tab>
      <v-tab :value="3">Abstract</v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab1">
      <v-tabs-window-item
        v-for="n in 3"
        :key="n"
        :value="n"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="prod in Product"
              :key="prod.id"
              cols="12"
              md="3"
            >
            <router-link :to="{ path: '/productdetails/' + prod.id }" class="btn"  style="text-decoration: none; color: inherit;">
            <v-card
    class="mx-auto"
    max-width="400"
  >
    <v-img
      class="align-end text-white"
      height="200"
      v-if="prod.Assets.data[0] !== undefined"
    :src="prod.Assets.data[0].links.full"
      cover
    >
    
    </v-img>
    <v-img
      class="align-end text-white"
      height="200"
      v-else
    src="../../public/images/No-Image1.png"
      cover
    >
   
    </v-img>
    <v-card-subtitle class="pt-4 text-start text-black">
      <v-icon color="#008768"  icon="mdi-web" size="large"></v-icon>  {{ prod.meeting_point_addr +','+ prod.end_point_addr }}
    </v-card-subtitle>

    <v-card-text>
      
      <div class="text-start">{{ prod.overview_points[0] }}</div>
      <div class="text-start">
       

        <v-rating class="text-start"
        color="#008768"
      v-model=" prod.product_review_summary.total_rating"
      half-increments
      length="6"
      readonly
    > </v-rating> 
      </div>

      <div class="text-start" v-if="prod.ProductAvailabilityUpcoming.data !=''">
        <p v-for="rate  of prod.ProductAvailabilityUpcoming.data" :key="rate.id">from ₹ {{rate.price  }}</p>
      
      </div>
      <div class="text-start" v-else>
        <p>from ₹ 0.00</p>
      
      </div>

    </v-card-text>

   
  </v-card></router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card> -->

    <h1 class="mt-7 text-h6 text-md-h5 text-lg-h4 font-weight-black">Top Destination</h1>
    <v-container fluid>
      <v-row>
        <v-col v-for="prod of destination" :key="prod.id" cols="12" md="3">

          <router-link :to="{ path: '/productdetails/' + prod.id }" class="btn"
            style="text-decoration: none; color: inherit;">
            <v-card class="mx-auto" max-width="400" rounded="lg">
              <v-img class="align-end text-white background" height="200" v-if="prod.Assets.data[0] !== undefined"
                :src="prod.Assets.data[0].links.full" cover>
                <v-card-title class="text-h6" style="margin-bottom: 5rem;">
                  {{ prod.title }}
                </v-card-title>
              </v-img>
              <v-img class="align-end text-white" height="200" v-else src="../../public/images/No-Image1.png" cover>
                <v-card-title class="text-h6" style="margin-bottom: 5rem;">
                  {{ prod.title }}
                </v-card-title>

              </v-img>
              <!-- <v-card-subtitle class="pt-4 text-start text-black">
      <v-icon color="#008768"  icon="mdi-web" size="large"></v-icon>  {{ prod.meeting_point_addr +','+ prod.end_point_addr }}
    </v-card-subtitle> -->



            </v-card></router-link>
        </v-col>

      </v-row>
    </v-container>




    <h1 class="mt-7 text-h6 text-md-h5 text-lg-h4 font-weight-black">Top Attraction</h1>
    <v-container fluid>
      <v-row>
        <v-col v-for="prod of attraction" :key="prod.id" cols="12" md="3">

          <router-link :to="{ path: '/productdetails/' + prod.id }" class="btn"
            style="text-decoration: none; color: inherit;">
            <v-card class="mx-auto" max-width="400" style="height: 11rem;" rounded="lg">



              <!-- <v-card-actions> -->
                <v-list-item class="w-100" style="padding: 0px;">
                  <template v-slot:prepend>
                    <v-img class="align-end text-white" width="176" height="176"
                      v-if="prod.Assets.data[0] !== undefined" :src="prod.Assets.data[0].links.thumb">
                      <v-card-title class="text-h6" style="margin-bottom: 5rem;">

                      </v-card-title>
                    </v-img>
                    <v-img class="align-end text-white" width="160" height="160" v-else src="../../public/images/No-Image1.png"
                      cover>
                      <v-card-title class="text-h6" style="margin-bottom: 5rem;">

                      </v-card-title>

                    </v-img>
                  </template>

                  <v-list-item-title class="font-weight-bold ml-4" style="text-overflow: ellipsis;text-align: start;"> {{ prod.title }}</v-list-item-title>


                </v-list-item>
              <!-- </v-card-actions> -->
            </v-card>


          </router-link>
        </v-col>

      </v-row>
    </v-container>


    <h1 class="mt-7 text-h6 text-md-h5 text-lg-h4 font-weight-black">Top Tours</h1>
    <v-container fluid>
      <v-row>
        <v-col v-for="prod of toptour" :key="prod.id" cols="12" md="3">

          <router-link :to="{ path: '/productdetails/' + prod.id }" class="btn"
            style="text-decoration: none; color: inherit;">
            <v-card class="mx-auto" max-width="400">
              <v-img class="align-end text-white" height="200" v-if="prod.Assets.data[0] !== undefined"
                :src="prod.Assets.data[0].links.full" cover>

              </v-img>
              <v-img class="align-end text-white" height="200" v-else src="../../public/images/No-Image1.png" cover>

              </v-img>
              <v-card-subtitle class="pt-4 text-start text-black">
                <v-icon color="#008768" icon="mdi-web" size="large"></v-icon> {{ prod.meeting_point_addr + ',' +
                  prod.end_point_addr }}
              </v-card-subtitle>

              <v-card-text>

                <div class="text-start" style="height: 5rem;overflow: hidden;">{{ prod.overview_points[0] }}</div>
                <div class="text-start">


                  <v-rating class="text-start" color="#008768" v-model="prod.product_review_summary.total_rating"
                    half-increments length="6" readonly> </v-rating>
                </div>

                <div class="text-start" v-if="prod.ProductAvailabilityUpcoming.data != ''">
                  <p v-for="rate of prod.ProductAvailabilityUpcoming.data" :key="rate.id">from ₹ {{ rate.price }}</p>

                </div>
                <div class="text-start" v-else>
                  <p>from ₹ 0.00</p>

                </div>

              </v-card-text>

            </v-card></router-link>
        </v-col>

      </v-row>
    </v-container>


    <Footer />

    <!-- <Footer/> -->
  </div>


</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios';
import http from '@/http';
import TopHeader from '@/components/Header/TopHeader.vue';
import Footer from '@/components/Footer/footer.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    TopHeader,
    VueDatePicker,
    Footer
    // ,Footer

  },
  data() {
    return {
      model: '',
      tab: '',
      tab1: '',
      date: '',
      date1: '',
      Product: [],
      toptour: [],
      destination: [],
      attraction: []

    }
  },
  async mounted() {
    axios.get(http.defaults.baseURL + 'api/ListProduct?include=ProductAvailabilityUpcoming')
      .then(response => {
        console.log('prod', response.data.data)
        this.Product = response.data.data;

        this.pagination = response.data.meta.pagination;
        console.log('per', this.pagination)
        this.previous = this.pagination.links.previous;
        this.per_page = this.pagination.per_page;
        this.current_page = this.pagination.current_page;
        this.total_page = this.pagination.total_pages;

        this.count = this.pagination.count;
        this.next = this.pagination.links.next;
        this.total = this.pagination.total;
        this.first = ((this.current_page - 1) * this.per_page) + 1;
        if (this.current_page == this.total) {

          this.last = (((this.current_page - 1) * this.per_page) + this.count);
        }
        else {
          this.last = (this.current_page * this.per_page);

        }


      });

    axios.get(http.defaults.baseURL + 'api/GetProductTopTour')
      .then(response => {
        var prodid = response.data.data;
        console.log('prodtest1', prodid)


        Object.values(prodid).forEach((value) => {
          var id = value.product_id;
          // console.log('prodid', id)
          // Object.values(id).forEach((value) => {
          console.log('id', value)
          axios.get(http.defaults.baseURL + 'api/ShowProduct/' + id + '?include=ProductAvailabilityUpcoming')
            .then(response => {
              console.log('top', response)
              this.toptour.push(response.data.data);

            })
          // });



        });
        // console.log('prodid',response.data.data)
      });


    axios.get(http.defaults.baseURL + 'api/GetProductDestination')
      .then(response => {
        var prodid = response.data.data;
        console.log('proddestination', prodid)


        Object.values(prodid).forEach((value) => {
          var id = value.product_id;
          // console.log('prodid', id)
          // Object.values(id).forEach((value) => {
          console.log('id', value)
          axios.get(http.defaults.baseURL + 'api/ShowProduct/' + id + '?include=ProductAvailabilityUpcoming')
            .then(response => {
              console.log('dest', response)
              this.destination.push(response.data.data);

            })
          // });



        });
        // console.log('prodid',response.data.data)
      });
    axios.get(http.defaults.baseURL + 'api/GetProductAttraction')
      .then(response => {
        var prodid = response.data.data;
        console.log('prodattraction', prodid)


        Object.values(prodid).forEach((value) => {
          var id = value.product_id;
          // console.log('prodid', id)
          // Object.values(id).forEach((value) => {
          console.log('id', value)
          axios.get(http.defaults.baseURL + 'api/ShowProduct/' + id + '?include=ProductAvailabilityUpcoming')
            .then(response => {
              console.log('dest', response)
              this.attraction.push(response.data.data);

            })
          // });



        });
        // console.log('prodid',response.data.data)
      });
  }
}
</script>
<style >
.v-img.background{
  background: rgb(26 25 24 / 60%);
  z-index: auto;

}
.v-img.background:hover{
  background: rgb(26 25 24 / 60%);
  z-index: 1;

}
/* .v-list-item {
padding: 0px!important;

} */
.v-list-item-title {
   font-size: 0.9rem;
    white-space: normal;
}
/* .dp__calendar {
    position: relative;
    height: 175px;
    overflow-y: scroll;
} */
.radio-end {
  align-items: end;
  display: flex;
  flex-direction: column;
}

.dp__calendar_row {
  height: 20px;
}
.dp__input {
height: 56px!important;
}
.datetop {

  max-width: 64%;
  padding-left: 7rem;
  padding-right: 7rem;
  height: 25%;
}

@media only screen and (max-width: 600px) {
  .aligncen {
    font-size: 25px;
    margin-right: 22%;
  }

  .aligncen1 {

    margin-right: 22%;
  }

  .datetop {
    margin-top: 5rem !important;
    margin-right: 27% !important;
    max-width: 50%;
    display: contents;

    height: 25%;
  }

  .width {
    width: 70%;
  }

  .cen {
    margin-left: 7rem;

  }
  .v-img.background{
  background: rgb(26 25 24 / 60%);
  z-index: auto;

}
.v-img.background:hover{
  background: rgb(26 25 24 / 60%);
  z-index: 1;

}
}
</style>
